import { Grid } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { FC } from 'react';
import ProportalBulletTile from './ProportalBulletTile';

interface IProportalTiles {
  hideTileDescriptionMarginTop?: boolean;
}

const features = [
  {
    description:
      'We manage every aspect of household employer tax compliance, from setting up tax account(s) to filing all required returns and managing tax agency correspondence. At tax time, we prep, file, and distribute W-2s to employees and post a client 1040 Packet containing their completed Schedule H along with records of any 1040-ES payments made through our service. When the time comes, we take care of closing down the state tax accounts.',
    title: 'End-to-End Tax Compliance — Guaranteed',
    key: 1,
  },
  {
    description:
      'Stay informed with on-demand access to organized tax records, payment details, and reporting tools. Our dashboard makes it easy to track payroll activity, download reports, and monitor compliance status without sifting through paperwork.',
    title: 'Full Financial Transparency in Real-Time',
    key: 2,
  },
  {
    description:
      'Our service automates complex payroll tasks—offering flexible payroll options tailored to household employment. Set default payroll amounts and we’ll withhold the correct taxes, and ensure timely direct deposits, and provide employees with access to paystub records. Payroll updates can be made in seconds using our simple online manager tool. Employees with fluctuating hours can also use our TimeTracker app to easily track and submit their hours for approval and automatic processing.',
    title: 'Streamlined Payroll Processing for Household Employees',
    key: 3,
  },
  {
    description:
      'Our intuitive online registration process allows clients and their employees to onboard in less than five minutes. If a client is transitioning over to our service, current year payroll records and/or tax forms can be securely uploaded to their registration.',
    title: 'Effortless Client Onboarding',
    key: 4,
  },
];

const ProportalTiles: FC<IProportalTiles> = ({ hideTileDescriptionMarginTop }) => {
  return (
    <Grid
      container
      direction="column"
      data-testid="tiles-container"
      paddingLeft={theme.spacing(6)}
      sx={{
        paddingLeft: {
          xs: theme.spacing(3),
          md: theme.spacing(6),
        },
        paddingRight: theme.spacing(3),
      }}>
      {features.map((feature) => (
        <Grid item key={feature.key}>
          <ProportalBulletTile
            title={feature.title}
            description={feature.description}
            hideTileDescriptionMarginTop={hideTileDescriptionMarginTop}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProportalTiles;
