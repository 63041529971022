import { theme } from '@care/material-ui-theme';
import { Box, Grid, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IProportalBulletTile {
  description: string | ReactNode;
  title: string | ReactNode;
  hideTileDescriptionMarginTop?: boolean;
}

const ProportalBulletTile: FC<IProportalBulletTile> = ({
  description,
  title,
  hideTileDescriptionMarginTop,
}) => {
  return (
    <Grid
      container
      alignItems="flex-start"
      direction="row"
      sx={{
        gap: {
          xs: theme.spacing(1),
          sm: theme.spacing(2),
          md: theme.spacing(3),
        },
      }}
      justifyContent="flex-start">
      <Box
        sx={{
          backgroundColor: theme.palette.care.navy[800],
          borderRadius: '50%',
          height: {
            xs: '12px',
            sm: '14px',
            md: '16px',
          },
          marginTop: theme.spacing(0.75),
          width: {
            xs: '12px',
            sm: '14px',
            md: '16px',
          },
        }}
      />
      <Grid item justifyContent="space-between" xs={11} md={10}>
        <Typography
          component="h3"
          sx={{
            textAlign: 'left',
          }}
          variant="h3">
          {title}
        </Typography>
        <Typography
          sx={{
            textAlign: 'left',
            margin: hideTileDescriptionMarginTop
              ? theme.spacing(0, 0, 1.5)
              : theme.spacing(3, 0, 3),
          }}
          variant="body2"
          color="secondary">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProportalBulletTile;
