import { Box, Container, Grid, Link, useMediaQuery, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import Image, { StaticImageData } from 'next/image';
import { theme } from '@care/material-ui-theme';
import { CLIENT_FEATURE_FLAGS, PAGE_PROPERTIES, PHONE_LABEL, PHONE_LINK } from '@/constants';
import Headset from '@/assets/images/headset.png';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { useFeatureFlags } from '@/components/contexts/FeatureFlagsContext';
import EnrollmentCTA from '../../EnrollmentCTA/EnrollmentCTA';

interface GetStartedProps {
  heading: string;
  subHeading: string;
  heroImageUri: StaticImageData;
}

const bannerVariants = {
  '0': 'Have questions? Call us now at',
  '1': 'Claim Exclusive Offer',
  '2': 'Try For Free',
};

const GetStarted: FC<GetStartedProps> = ({ heading, subHeading, heroImageUri }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { getFeatureFlagValue } = useFeatureFlags();
  const banner = getFeatureFlagValue(CLIENT_FEATURE_FLAGS.HOMEPAY_BANNER_TEST);

  const handlePhoneClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Main VHP - Phone Banner',
        cta_text: `${PHONE_LABEL}`,
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: PAGE_PROPERTIES.mainVHP,
    });
  };

  useEffect(() => {
    logAnalyticsEvent({
      name: AmplitudeEventName.testExposure,
      pageProperties: PAGE_PROPERTIES.mainVHP,
      data: {
        test_name: CLIENT_FEATURE_FLAGS.HOMEPAY_BANNER_TEST,
        test_variant: `${Number(banner)}`,
      },
    });
  }, [banner]);

  return (
    <Container disableGutters maxWidth={false}>
      <Box
        sx={{
          alignItems: 'center',
          background: theme.palette.care?.red[100],
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          padding: theme.spacing(0.5),
        }}
        width="100%">
        <Image alt="Call us now" height={40} src={Headset} width={40} />
        <Typography variant="body3">
          {bannerVariants[banner as keyof typeof bannerVariants]}
        </Typography>
        <Link
          sx={{
            margin: theme.spacing(0, 0.5),
            fontWeight: 700,
          }}
          data-testid="phone-number-link"
          onClick={handlePhoneClick}
          href={PHONE_LINK}
          rel="nofollow">
          {PHONE_LABEL}
        </Link>
      </Box>
      <Container
        sx={{
          background: theme.palette.care?.navy[900],
          [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(-6.25),
          },
          overflow: 'visible',
        }}
        disableGutters
        maxWidth={false}>
        <Grid
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
          container>
          <Grid item md={6} sm={12}>
            <Box
              sx={{
                paddingLeft: theme.spacing(2),
                [theme.breakpoints.down('md')]: {
                  paddingLeft: 0,
                },
              }}>
              <Box
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    padding: theme.spacing(8, 4, 8),
                  },
                  [theme.breakpoints.down('md')]: {
                    padding: theme.spacing(2),
                  },
                }}>
                <Typography
                  sx={{
                    color: theme.palette.care?.white,
                    margin: theme.spacing(0, 'auto'),
                    paddingTop: 0,
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      paddingTop: theme.spacing(1.5),
                      textAlign: 'center',
                    },
                  }}
                  variant={isMobile ? 'serifHeadline2' : 'serifDisplay2'}>
                  {heading}
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.care?.white,
                    fontSize: '28px',
                    letterSpacing: '0.5px',
                    lineHeight: '34px',
                    margin: theme.spacing(2.5, 'auto', 1.875, 0),
                    width: '82%',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '18px',
                      lineHeight: '24px',
                      marginLeft: 0,
                      textAlign: 'center',
                      width: '100%',
                    },
                  }}
                  variant={isMobile ? 'body1' : undefined}>
                  {subHeading}
                </Typography>
                <EnrollmentCTA
                  ctaLocation="HomePay VHP Variant 2 Hero"
                  pageProperties={PAGE_PROPERTIES.mainVHP}
                />
              </Box>
            </Box>
          </Grid>
          {!isMobile && (
            <Grid
              sx={{
                overflow: 'visible',
                position: 'relative',
              }}
              item
              md={6}
              sm={12}>
              <Box
                sx={{
                  [theme.breakpoints.down('lg')]: {
                    'clip-path': 'circle(75% at 75% 70%)',
                  },
                  height: 450,
                  width: '100%',
                  'clip-path': 'circle(85% at 75% 57.5%)',
                }}>
                <Image
                  src={heroImageUri}
                  width={800}
                  height={450}
                  layout="fill"
                  objectPosition="right"
                  objectFit="cover"
                  data-testid="hero-image"
                  quality={100}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Container>
  );
};

export default GetStarted;
